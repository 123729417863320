import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

import MarkdownViewer from "@components/MarkdownViewer"
function OurMission(props) {
  return (
    <section className="body-section our-mission color-back">
      <div className={`columns has-text-centered-tablet`}>
        <div className={`column is-${props.sideColumnIs}`}></div>
        <div className="column is-full-width">
          <MarkdownViewer markdown={props.post.ourMission} />
        </div>
        <div className={`column is-${props.sideColumnIs}`}></div>
      </div>
    </section>
  )
}

export default OurMission
