import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft'
import { faFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload'

var classNames = require("classnames")

class Button extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  goBack() {
    window.history.back()
  }

  render() {
    var btnClass = classNames('standard-button', this.props.className, {
      "standalone": this.props.standalone,
      "go-back-button-top-left": this.props.goBack,
      white: this.props.white ? this.props.white : false,
      border: this.props.border ? this.props.border : false,
      minimal: this.props.minimal ? this.props.minimal : false,
      contained: this.props.contained ? this.props.contained : false
    })

    var backBtnClass = classNames('go-back-button', this.props.className, {
      "go-back-button": true,
      "go-back-button-top-left": this.props.goBack,
      white: this.props.white ? this.props.white : false,
      "white-mobile": this.props.whiteMobile ? this.props.whiteMobile : false,
      minimal: this.props.minimal ? this.props.minimal : false
    })

    // for telephone links
    if (this.props.tel) {
      return (
        <a className={btnClass} href={this.props.href}>
          {this.props.buttonText}
        </a>
      )
    }
    // for external links
    if (this.props.external) {
      return (
        <a className={btnClass} href={this.props.href} target="_blank" rel="noopener noreferrer">
          {this.props.buttonText}
        </a>
      )
    }
    // for anchors DEPRECATED
    // if (this.props.anchor) {
    //   return (
    //     <a className={btnClass} href={this.props.href}>
    //       {this.props.buttonText}
    //     </a>
    //   )
    // }
    // for downloads
    if (this.props.download) {
      return (
        <a className={btnClass} href={this.props.href} target="_blank" download>
          <FontAwesomeIcon className="button-icon" icon={faFileDownload} />
          {this.props.buttonText}
        </a>
      )
    }
    // Insert long left arrow for Back "Button"
    else if (this.props.goBack && !this.props.href) {
      return (
        <a className={backBtnClass} onClick={this.goBack}>
          <FontAwesomeIcon className="button-icon" icon={faLongArrowLeft} />
          {this.props.buttonText}
        </a>
      )
    } else if (this.props.goBack) {
      return (
        <Link className={backBtnClass} to={this.props.href}>
          <FontAwesomeIcon className="button-icon" icon={faLongArrowLeft} />
          {this.props.buttonText}
        </Link>
      )
    }
    // Classic Gatsby Link styled as a button; assumed to not be external
    else {
      if (!this.props.href) throw "No href given to Button. Props are as follows: " + JSON.stringify(this.props);
      let href = this.props.href;
      if (href.charAt(0) !== "/") href = "/" + href;
      if (href.charAt(href.length - 1) !== "/") href = href + "/";
      return (
        <Link className={btnClass} to={href}>
          {this.props.buttonText}
        </Link>
      )
    }
  }
}

Button.propTypes = {
  standalone: PropTypes.bool,
  anchor: PropTypes.bool,
  minimal: PropTypes.bool,
  white: PropTypes.bool,
  border: PropTypes.bool,
  contained: PropTypes.bool,
  goBack: PropTypes.bool,
  download: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string
}

export default Button
