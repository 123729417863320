import React, { Component } from "react"
import classNames from "classnames"
import Button from "@components/Button/Button"
import PropTypes from "prop-types"
import FacewallImage from "../../components/carousel/FacewallImage"

class FixedFacewallHomepage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    // Only allow rows of four, reviews should be sorted by most recent to least recent
    let reviewCount = this.props.reviews.length
    let nearestFourth = Math.floor((reviewCount / 4) * 4)
    let reviews = this.props.reviews.slice(0, nearestFourth)
    const images = reviews.map((review, i) => {
      return (
        <FacewallImage
          columnClassName={this.props.columnClassName}
          className={i > 3 ? "is-hidden-mobile" : ""}
          key={review.title}
          imageUrl={review.thumbnailPublicId}
          reviewerName={review.reviewerName}
          procedure={review.mainProcedureDone}
          procedureEs={review.mainProcedureDoneEs}
          url={review.title}
          monthYear={review.monthYear}
          middleColumnsis={this.props.middleColumnsis}
          language={this.props.language}
          youtube={review.youtube}
        />
      )
    })

    return (
      <section
        className={classNames(
          "home-section fixed-facewall",
          this.props.className
        )}
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        {this.props.heading && (
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
              <h3>{this.props.heading}</h3>
              {this.props.blurb}
            </div>
            <div className="column is-2"></div>
          </div>
        )}
        <div className="columns is-multiline procedure-patient-row">
          {images}
        </div>
        <div className="columns has-text-centered">
          <div className="column">
            {this.props.language === "es" ? (
              <Button
                contained
                buttonText="Más Pacientes"
                href="/es/historias-de-pacientes/"
              />
            ) : (
              <Button
                contained
                buttonText="More Patients"
                href="/patient-stories/"
              />
            )}
          </div>
        </div>
      </section>
    )
  }
}

FixedFacewallHomepage.propTypes = {
  reviews: PropTypes.array.isRequired,
  className: PropTypes.string,
  middleColumnsis: PropTypes.number,
  sideColumnIs: PropTypes.number,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  heading: PropTypes.string,
  blurb: PropTypes.element
}

export default FixedFacewallHomepage
