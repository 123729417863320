import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

var classNames = require("classnames")

import MarkdownViewer from "@components/MarkdownViewer"
import ButtonGroupMap from "../Button/ButtonGroupMap"

class MarkdownSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <section
        className="body-sections section joshua-tree-content"
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        <div className="columns">
          <div className={`column is-${this.props.sideColumnIs}`}></div>
          <div className="column">
            <MarkdownViewer markdown={this.props.textSection} />

            {this.props.buttons && (
              <ButtonGroupMap isCentered buttons={this.props.buttons} />
            )}
          </div>
          <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
      </section>
    )
  }
}

MarkdownSection.propTypes = {
  textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number
}

export default MarkdownSection
