import React from "react"
import ImageMeta from "../../components/ImageMeta"
import Layout from "../../components/layout"
import Seamless from "../../components/Seamless"
import { SharedStateProvider } from "../../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import CTA from "../../components/CTA/CTA"
import SEO from "../../components/seo"
import TextPhoto from "../../components/TextPhoto/TextPhoto"
import { graphql } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { Link } from "gatsby"
import { instructions } from "../../cms/collections/instructions"

var classNames = require("classnames")

import MarkdownViewer from "@components/MarkdownViewer"
function TopSection(props) {
  return (
    <>
      <div className="columns top-section white-back">
        <div className="column is-4"></div>
        <div className="column">
          <h1
            className="mobile-left has-text-centered"
            style={{ marginTop: "0" }}>
            {props.post.heading}
          </h1>

          <div className="has-text-centered-tablet">
            <MarkdownViewer markdown={props.post.topBlurb} />
          </div>
        </div>
        <div className="column is-4"></div>
      </div>
      <div className="columns has-text-centered">
        <div className="column is-full-width">
          <TheaterVideo
            buttonClass="contained"
            videoUrl={`https://www.youtube.com/watch?v=${props.post.mainVideo.youtube}`}
            controls={true}
            playing={true}
            overVideo={true}
            language={props.language}>
            <div className="theater-image-wrapper">
              <ImageMeta
                cloudName="nuvolum"
                className="show-desktop-only"
                publicId={props.post.mainVideo.imageId}
                width="auto"
                responsive></ImageMeta>
              <ImageMeta
                cloudName="nuvolum"
                className="show-desktop-only-inverse"
                publicId={props.post.mainVideo.imageIdMobile}
                width="auto"
                responsive></ImageMeta>
            </div>
          </TheaterVideo>
        </div>
      </div>
    </>
  )
}

export default TopSection
