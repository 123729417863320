import React from "react"
import Button from "@components/Button/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageMeta from "../components/ImageMeta"

import "../css/pages/w4w-23.scss"
import { Link } from "gatsby"

const W4W = () => {
  const image = name =>
    `https://res.cloudinary.com/nuvolum/image/upload/e_trim,q_99,f_auto/v1/Programs/${name}`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />

      <div className="w4w-23">
        <div className="w4w-23__container">
          <div className="w4w-23__hero">
            <img
              className="w4w-23__hero--logo"
              src={image("2024-SDOS-w4w-logo")}
              alt="wisdom for wisdom program logo"
            />
            <div>
              <img
                className="w4w-23__hero--banner"
                src="https://res.cloudinary.com/nuvolum/image/upload/q_99,e_bgremoval/v1/Programs/2024-SDOS-w4w-hero-image.png"
                alt="wisdom for wisdom grid art"
              />
            </div>
            {/* <h1>
              HELPING
              <br />
              STUDENTS
              <br />
              SAVE MONEY
              <br />
              FOR COLLEGE
            </h1> */}
          </div>
        </div>

        <div className="w4w-23__section">
          <div className="w4w-23__container">
            <h1>Saving for College? We Want to Help!</h1>
            <p>
              College costs are higher than ever. We’re doing our part to help
              local families save money.
            </p>

            <p>
              Our 2024 Wisdom For Wisdom program seeks to help students save for
              college by providing free wisdom teeth removal procedures. In
              addition to providing the procedure at no cost, we will also treat
              the recipients with EXPAREL<sup>®</sup>, a non-opioid,
              long-lasting treatment that will ensure a healthy recovery after
              the procedure.
            </p>
            <p>
              This year we selected two San Diego residents to be our Wisdom For
              Wisdom recipients: Adam Rosal and Michael Barvinchak.
            </p>
            <p>
              In his application, Adam Rosal, a student at San Diego City
              College, wrote that this program would offer more than financial
              relief—it would offer a lifeline. Because he endures daily pain
              caused by horizontally impacted wisdom teeth and cavities, his
              quality of life suffers. Without dental insurance, he is unable to
              afford the procedure independently. Wisdom for Wisdom allows him
              to redirect funds toward tuition, textbooks, lab equipment, class
              materials, and a laptop for online classes, allowing him to
              prioritize his academic future.
            </p>
            <p>
              As a soon-to-be Mira Costa Community College graduate, Michael
              Barvinchak sees the 2024 Wisdom for Wisdom program as a chance to
              pursue his education at San Diego State University next year, as
              it will significantly alleviate financial strain. Since he was 18,
              Michael has balanced being a full-time student with work and
              funding his own education. Large expenses loom on the horizon as
              he plans to transfer to SDSU. The Wisdom for Wisdom program covers
              his wisdom teeth removal procedure, easing financial stress,
              allowing him to focus on college, and continuing to a successful
              future.
            </p>
            <p>
              The Wisdom for Wisdom program empowers individuals like Adam and
              Michael to pursue their educational aspirations without the burden
              of dental expenses.
            </p>

            <p>
              To follow Adam and Michael’s story and procedure, follow us on{" "}
              <a
                href="https://www.facebook.com/SanDiegoOralSurgery/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/sandiegooralsurgery/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>
              .
            </p>

            <p>
              We wish every student the best as they pursue their academic
              endeavors.
            </p>

            <p>
              Learn more about{" "}
              <Link
                title="Learn more about wisdom teeth removal"
                to="/oral-surgery-procedures/wisdom-teeth-removal-san-diego-ca/">
                wisdom teeth removal
              </Link>
              .
            </p>
          </div>
        </div>

        <div className="w4w-23__section">
          <div className="w4w-23__container">
            <div className="w4w-23__grid">
              <ImageMeta publicId="SDOS/DEV/w4w-2024-recipient-1" />
              <ImageMeta publicId="SDOS/DEV/w4w-2024-recipient-2" />
            </div>
          </div>
        </div>

        <div className="w4w-23__rounded-container">
          <div className="w4w-23__section">
            <div className="w4w-23__container">
              <div>
                <div>
                  <h3>Just the facts...</h3>
                  <ul>
                    <li>
                      Wisdom teeth are the only teeth that don’t form before
                      birth.
                    </li>
                    <li>
                      85% of wisdom teeth will need to be removed at some point.
                    </li>
                    <li>
                      Wisdom teeth are the last of the 32 adult permanent teeth
                      to develop and erupt.
                    </li>
                    <li>
                      Wisdom teeth that are impacted (they cannot erupt
                      completely) can cause problems in your mouth and sinus.
                    </li>
                    <li>
                      Wisdom teeth often appear between the ages of 17 and 25.
                    </li>
                  </ul>
                </div>
                {/* <div>
                  <h3>How to Apply:</h3>
                  <ol>
                    <li>Watch a video about wisdom teeth extraction.</li>
                    <li>Take a short quiz.</li>
                    <li>Submit your application.</li>
                  </ol>
                  <p>
                    We wish every student the best as they pursue their academic
                    endeavors.{" "}
                  </p>
                  <div className="w4w-23__btn-group">
                    <Button
                      href="/w4w-step-1"
                      buttonText="Start Quiz"
                      appearance="contained"
                      destination="internal"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default W4W
