import React from 'react';

// src prop should look like this
// src="https://{SUBDOMAIN_HERE}.seamlessdocs.com/f/{FORM_ALIAS_HERE}?embedded=true"
// https://secureform.seamlessdocs.com/f/7a45298d241841cf4132767d09af3871?embedded=true

// TODO:
// add height prop next time it is needed

const SeamlessIFrame = ({ src }) => {
    
    return (
        <iframe 
        class='seamlessdocsEmbededIframe' 
        src={src} 
        width="100%" 
        height="800px" 
        frameborder="0" 
        allowtransparency="true" 
        sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
        >
        </iframe>
    )
}

export default SeamlessIFrame