import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@components/Button/Button"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Link } from "gatsby"

const VeteransTerms = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title="Terms & Conditions for the Smiles For Veterans Program"
          description="Please fully review the terms & conditions of the Smiles For Veterans program prior to submitting of your application."
        />

        <div className="joshua-tree-content program-page-wisdom">
          <div className="columns veterans-top-section has-text-centered">
            <div className="column">
              <h1>SMILES FOR VETERANS APPLICATION</h1>
            </div>
          </div>

          <section className="body-section">
            <div className="columns">
              <div className="column is-6"></div>
              <div className="column">
                <div className="event-container single-column-event">
                  <div style={{ textAlign: "center", fontWeight: "bold" }}>
                    <p className="p1">
                      <b>Official TERMS &amp; CONDITIONS</b>
                    </p>
                    <p className="p3">
                      <b>
                        NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY. A
                        PURCHASE OR PAYMENT WILL NOT INCREASE YOUR CHANCES OF
                        BEING SELECTED.
                      </b>
                    </p>
                    <p className="p4">
                      OPEN ONLY TO ELIGIBLE LEGAL RESIDENTS OF THE UNITED STATES
                      OF AMERICA WHO RESIDE IN OR NEAR SAN DIEGO COUNTY,
                      CALIFORNIA AND WHO ARE AT LEAST 18 YEARS OLD AS OF THE
                      DATE OF APPLYING. THIS PROGRAM IS VOID WHERE PROHIBITED.
                    </p>
                    <p className="p4">
                      READ THESE OFFICIAL TERMS &amp; CONDITIONS CAREFULLY AS
                      YOU WILL BE BOUND BY THEM.
                    </p>
                    <br />
                  </div>
                </div>
              </div>
              <div className="column is-6"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default VeteransTerms
