import React from "react"
import Layout from "../components/layout"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

import MarkdownViewer from "@components/MarkdownViewer"

const ThankYouPage = props => {
  const post = props.data.allUniquePagesJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout language="en">
        <SEO
          lang="en"
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={props.location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="joshua-tree-content">
          <div className="columns">
            <div className="column">
              <div className="columns top-section text-section is-mobile color-back">
                <div className="column is-4"></div>
                <div className="column is-16">
                  <div>
                    <MarkdownViewer markdown={post.body} />
                  </div>
                </div>
                <div className="column is-4"></div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query thankYouPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        body
        metaTitle
        metaDescription
      }
    }
  }
`

export default ThankYouPage
