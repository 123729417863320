import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@components/Button/Button"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Link } from "gatsby"

const ExparelPage = () => {
  let language = "en"

  // active, closed
  let programStatus = "closed"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title="EXPAREL® | non-narcotic pain relief option in San Diego, CA"
          description="Oral Surgery & Dental Implant Specialists of San Diego offers EXPAREL for patients undergoing surgery. Learn more about this long-lasting, opioid-free option."
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content">
          <div className="columns top-section white-back">
            <div className="column is-4"></div>
            <div className="column">
              <h1>
                EXPAREL<sup>®</sup> Helps Control Pain Without Opioids
              </h1>
              <p>
                EXPAREL is a revolutionary post-surgical pain relief option. A
                single dose of EXPAREL administered during the surgery takes
                effect before the anesthesia wears off and continues to provide
                pain relief for several days, which is the time when
                post-surgical pain is at its peak.{" "}
                <a
                  href="https://www.exparel.com/patient/non-opioid-pain-medication"
                  target="_blank">
                  EXPAREL
                </a>{" "}
                is so effective it reduces or even eliminates the need for
                opioids to manage post-operative discomfort.
              </p>

              <p>
                Learn about the anesthesia options offered by The Oral Surgery &
                Dental Implant Specialists of San Diego by visiting our{" "}
                <Link to="/anesthesia-options/">Anesthesia</Link> page.
              </p>

              <h3>The Risk of Managing Post-Operative Pain With Opioids</h3>
              <p>
                Opioids are narcotics. They are commonly prescribed by doctors
                for patients to manage pain after surgery. However, opioids are
                highly addictive and carry serious side effects if they are
                abused.
              </p>

              <p>Prescription opioid-based drugs include:</p>
              <ul>
                <li>Codeine (only available in generic form)</li>
                <li>
                  Fentanyl (Actiq<sup>®</sup>, Duragesic<sup>®</sup>, Fentora
                  <sup>®</sup>, Abstral<sup>®</sup>, Onsolis<sup>®</sup>)
                </li>
                <li>
                  Hydrocodone (Hysingla ER<sup>®</sup>, Zohydro ER<sup>®</sup>)
                </li>
                <li>
                  Hydrocodone/acetaminophen (Lorcet<sup>®</sup>, Lortab
                  <sup>®</sup>, Norco<sup>®</sup>, Vicodin<sup>®</sup>)
                </li>
                <li>
                  Hydromorphone (Dilaudid<sup>®</sup>, Exalgo<sup>®</sup>)
                </li>
                <li>
                  Meperidine (Demerol<sup>®</sup>)
                </li>
                <li>
                  Morphine (Kadian<sup>®</sup>, MS Contin<sup>®</sup>,
                  Morphabond<sup>®</sup>)
                </li>
                <li>
                  Oxycodone (OxyContin<sup>®</sup>, Oxaydo<sup>®</sup>)
                </li>
                <li>
                  Oxycodone and acetaminophen (Percocet<sup>®</sup>, Roxicet
                  <sup>®</sup>)
                </li>
              </ul>

              <p>
                For many years, prescribing an opioid-based medication was the
                standard treatment for post-surgical pain relief. Unfortunately,
                this has led to an opioid addiction crisis in our city and
                across our country.
              </p>
              <ul>
                <li>
                  According to the California Department of Public Health
                  (CDPH), San Diego had more than 2,200 accidental deaths
                  involving prescription opioids over the past decade.
                </li>
                <li>
                  In 2018, there were 769 emergency department visits related to
                  opioids in San Diego County. (CDPH)
                </li>
                <li>
                  In 2018, there were 268 deaths involving opioids in San Diego
                  County. (CDPH)
                </li>
                <li>
                  According to CNN, an estimated 10.3 million Americans aged 12
                  and older misused opioids in 2018.
                </li>
              </ul>

              <h3>Learn More</h3>
              <p>
                At The Oral Surgery & Dental Implant Specialists of San Diego,
                patient safety comes first. Dr. Koshgerian and Dr. Shelton offer
                EXPAREL to their patients as a leading alternative to
                prescribing opioid-based medications.{" "}
                <Link to="/contact/">Contact</Link> our office to learn more
                about your options for pain management.{" "}
              </p>
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default ExparelPage
